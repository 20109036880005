import React, { useEffect, useState } from 'react'
import { useStateValue } from '../context/StateProvider'
import { connectWallet, initContract } from '../utils'
import { formatUnits } from 'ethers'
import WalletManager from './WalletManager'
import { CiVault } from "react-icons/ci"


const Reserve = () => {
    const [{abi, wallet, signer, contractAddress, reserve}, dispatch] = useStateValue()
    const [fetching, setFetching] = useState(false)

    const getReserve = async () => {
        setFetching(true)
        try {
            let contractInstance
            if (signer === null) {
                const {signer: _signer} = await connectWallet()
                contractInstance = await initContract(contractAddress, abi, _signer)
            } else
                contractInstance = await initContract(contractAddress, abi, signer)
            const currentReserve = await contractInstance.reserve()
            const claimsCountsValue = await contractInstance.claims()
            const referralsCount = await contractInstance.getReferralCounts(wallet)
            const reserveInETH = formatUnits(currentReserve)
            
            dispatch({
                type: 'SET_RESERVE',
                claimsCounts: parseInt(claimsCountsValue),
                referrals: parseInt(referralsCount),
                reserve: parseFloat(reserveInETH) === 0 ? '0.000' : reserveInETH
            })
        } catch(err) {
            dispatch({
                type: 'SET_NOTIFICATION',
                data: {
                    type: 'error',
                    message: err.reason?.message || err.message || JSON.stringify(err)
                }
            })
        } finally {
            setFetching(false)
        }
    }

    useEffect(() => {
        if (wallet != null && reserve === null)
            getReserve()
    }, [signer, wallet])
    return (
        wallet === null ?
        <WalletManager /> :
        <div className='x2-container'>
            { fetching ? 
                <div>loading...</div> : 
                <div className='x2-box'>
                    <h3>Current Reserve Balance</h3>
                    <div className="x2-text-icon-content">
                        <font>{(parseFloat(reserve) - 263000000).toLocaleString()}</font>
                        <CiVault />
                    </div>
                </div>
            }
        </div>
    )
}

export default Reserve
