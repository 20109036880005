import React from 'react'
import { useStateValue } from '../context/StateProvider'

const Guide = () => {
    const [{ tokenName, airTokenName, promoTitle, minReferralCount, minTokenBalanceRequired }] = useStateValue()

    return (
        <div className='content'>
            <div className="content-container guide">
                <h1>The Guide</h1>
                <div className="row">
                    <h3>Prerequisites</h3>
                    <article>
                        <ol>
                            <li>A crypto wallet.</li>
                            <li>A minimum amount of {minTokenBalanceRequired} {tokenName}.</li>
                        </ol>
                    </article>
                </div>
                <div className="row">
                    <article>
                        <h3>How To Join {promoTitle} {tokenName} Airdrop</h3>
                        <p>Follow the steps below carefully to join the airdrop.</p>
                    </article>
                    <article>
                        <h3>Connect Your Wallet</h3>
                        <p>
                            After making sure you have satisfied the above prerequisites, connect your wallet to our decentralized application by clicking on the "Connect Wallet" button at the top right conner of the app in desktop view or in the menu on the mobile view.
                        </p>
                    </article>
                    <article>
                        <h3>Check Eligibility</h3>
                        <p>
                            Clicking on the "Check Eligibility" button on the main page of this app, allows the app to check your eligibility to claim the airdrop. <span className="highlight">(Check if you HODL {tokenName})</span> 
                        </p>
                    </article>
                    <article>
                        <h3>Claim Air Tokens</h3>
                        <p>
                            The "Air Tokens" serves as a fail-safe method designed to protect against "repeat request" and other form(s) of malicious attacks (possible) and protect the entire Airdrop Campaign.
                        </p>
                    </article>
                    <article>
                        <h3>Convert {airTokenName} to {tokenName} @ 1:1</h3>
                        <p>
                            After completing the "Claim Air Token" method and claimed the air token, the Airdrop reserve needs to first get empty (or this process can be fast-tracked once a wallet refers up to the minimum ({minReferralCount}) required referral count), after which all participant can click on the "Convert AirToken" button at the top right conner of the DApp and perform the conversion process.
                        </p>
                    </article>
                    <article>
                        <h3>Things to Note</h3>
                        <p className='highlight'>
                            You can earn more of the allocated reserve by inviting friends and family to join the campaign. The core motivation for this airdrop is 
                            <br/><br/>1. Reward our strong and loyal community.  
                            <br/>2. Increasing our current token holders (at the start of this campaign) from 76K+ to 100K+ holders and we believe inviting new members to join in this campaign will help in the actualization of this goal.
                        </p>
                        <p>
                            You are required to still posses a minimum of your initial {tokenName} balance (as at the time of claiming the airdrop) when converting from {airTokenName} to {tokenName}. 
                        </p>
                        <p>
                            The time of day when you perform the Air Token Claim can greatly affect the quantity of Air Tokens you receive.
                        </p>
                        <ul>
                            <li><span className="highlight">Morning</span> 7% - 10% of {tokenName}(s) balance at time of transaction.</li>
                            <li><span className="highlight">Afternoon</span> 5% - 7% of {tokenName}(s) balance at time of transaction.</li>
                            <li><span className="highlight">Evening</span> 3% - 5% of {tokenName}(s) balance at time of transaction.</li>
                            <li><span className="highlight">Night</span> 1% - 3% of {tokenName}(s) balance at time of transaction.</li>
                        </ul> 
                    </article>
                </div>
            </div>
        </div>
    )
}

export default Guide
