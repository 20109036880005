import React from 'react'

import found from '../media/images/logo-main.png'
import stopping from '../media/images/stopping.png'
import trusted from '../media/images/trusted.webp'

const Empower = () => {
    return (
        <div className='empower'>
            <div className="empower-container">
                <h1 style={{color:"#ddd"}}>Join us as we empower millions through Trust</h1>
                <div className="montage-case">
                    <div className="montage">
                        <div className="montage-row">
                            <div className="montage-text">
                                <h1>Founded 2022</h1>
                                <p>
                                    In the vast landscape of the cryptocurrency world, a unique journey began with the inception of COQ INU. Founded on the principles of decentralization, innovation, and community engagement, COQ INU has embarked on a thrilling adventure that continues to captivate the hearts of crypto enthusiasts.
                                </p>
                            </div>
                            <div className="montage-icon">
                                <img src={found} alt="" />
                            </div>
                        </div>
                        <div className="montage-row">
                            <div className="montage-icon">
                                <img src={trusted} alt="" />
                            </div>
                            <div className="montage-text">
                                <h1>Powered by 40K+ wallets</h1>
                                <p>
                                    Embraced by over 40,000 wallets, our platform has earned trust through unwavering reliability, innovative solutions, and a commitment to exceeding expectations. Join the community that believes in the power of what we offer a testament to our dedication to excellence.
                                </p>
                            </div>
                        </div>
                        <div className="montage-row">
                            <div className="montage-text">
                                <h1>We’re not stopping there</h1>
                                <p>
                                    Born from collective friendship, deeply rooted in memetics and humor, Coq Inu is not your average meme coin - it is a way of life.
                                </p>
                            </div>
                            <div className="montage-icon">
                                <img src={stopping} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Empower
